<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center background-cata" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mx-auto self-center">
      <div>
        <div slot="no-body" class="login-box">
          
          <div class="vx-row no-gutter justify-center items-center">
            <img :src="logoCata" >
          </div>
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col sm:w-full md:w-full lg:w-full">
              <div class="px-8 pt-8 login-tabs-container">

                <div class="vx-card__title mb-10">
                  <h4 class="mb-4">Login</h4>
                  <p>{{ $t('login.welcome') }}</p>
                </div>
 
                <login-jwt></login-jwt>

              </div>
            </div>
          </div>

          <div class="vx-row no-gutter justify-center items-center">
            <!--<img :src="logoFooter" class="logo-footer">-->
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import LoginJwt from "./LoginJWT.vue"

export default {
  data:  () => ({
    logoCata: require('@/assets/images/logo/cata_v2.png'),
    logoFooter: require('@/assets/images/logo/cata_solution.png')
  }),
  components: {
    LoginJwt
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";

.login-tabs-container {
  min-height: 350px;
}

.background-cata {
  background: $cata-bg;
}

.login-logo{
  height: 170px;
  margin-bottom: 55px;
}

.login-box {
  background:none !important;
}

.login-box p, h4 {
  color:white;
}

</style>
