<template>
  <div class="login-box">
    <vs-input
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder="Email"
        v-model="email"
        v-on:keyup.enter="loginJWT"
        class="w-full login-input"/>
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-input
        data-vv-validate-on="blur"
        v-validate="'required|min:4|max:10'"
        type="password"
        name="password"
        icon-no-border
        icon="icon icon-lock"
        icon-pack="feather"
        label-placeholder="Password"
        v-model="password"
        v-on:keyup.enter="loginJWT"
        class="w-full mt-10 login-input" />
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <div class="flex flex-wrap justify-between my-5">
      <!--<vs-checkbox v-model="remember" vs-value="true" style="color:white">{{ $t('login.rememberMe') }}</vs-checkbox>-->

      <router-link to="/pages/forgot-password">{{ $t('login.forgotPassword') }}</router-link>
    </div>

    <div class="float-right mb-3" >
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
    
  </div>
</template>

<script>
import i18n from '../../../i18n/i18n'

export default {
  data() {
    return {
      email: '',
      password: '',
      checkbox_remember_me: false,
      remember: false
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != '' && this.password != '';
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          color: 'warning'
        })

        return false
      }
      return true
    },
    loginJWT() {

      if (!this.checkLogin()) return

      // Loading
      this.$vs.loading()

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password
        }
      }

      this.$store.dispatch('auth/loginJWT', payload)
        .then(() => { this.$vs.loading.close() })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title:  i18n.t('dialog.error'),
            text: error.message,
            color: 'danger'
          })
        })
    },
    registerUser() {
      if (!this.checkLogin()) return
      this.$router.push('/pages/register').catch(() => {})
    }
  }
}

</script>

<style lang="scss" scoped>

.login-box a:active, a:visited, a:hover, a {
  color:white;
}

.input-span-placeholder{
  font-weight: bold;
}

</style>
